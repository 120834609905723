/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.logoutAlert {
    --min-width: 25% !important;
}

ion-icon {
    pointer-events: none;
}

.buttonContainer {
    padding: 2%;
}

.contentCentre {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
}

ion-button, ion-segment-button, .custom-button {
    text-transform: capitalize !important;
    --border-radius: 15px;
}
ion-button {
    --padding-start: 40px;
    --padding-end: 40px;
}
.modalcss .modal-wrapper {
    height: auto;
    width: auto;
    top: 20%;
    right: 10%;
    left: 10%;
    bottom: 20%;
    position: absolute;
}
.replyModal {
    border-radius: 15px;
    --width: 40%;
}
@media only screen and (max-width:900px) {
    .replyModal {
        --width: 80%;
    }
}
.meetingModal .modal-wrapper {
    border: var(--ion-color-dark) solid 1px;
    border-radius: 15px;
    height: inherit;
    width: 85%;
    top: 32%;
    bottom: 37%;
    position: absolute;
}

.loadingModal .modal-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #FFFFFF00;
}

.userInfoPopover {
    --height: 20%;
}

.queryGuidePopover .popover-content {
    --width: 95%;
    --backdrop-opacity: 98%;
}

.dropdownPopover .popover-content {
    --width: max-content;
    --min-width: 20%;
}

@media screen and (max-width:700px) {
    .popovercss .popover-content {
        background: #222;
        --width: 75%;
        --height: auto;
        max-height: 70%;
    }
}

.alert-class .alert-wrapper {
    --min-width: 95%;
    .text {
        text-align: left;
        width: 100%;
        color: var(--ion-color-dark);
        .title {
            font-weight: bold;
        }
    }
    .alert-radio.sc-ion-alert-md,
    .alert-radio.sc-ion-alert-ios {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }
    .alert-radio-label.sc-ion-alert-md,
    .alert-radio-label.sc-ion-alert-ios {
        white-space: normal;
    }
}

.toast-css {
    --color: var(--ion-color-primary);
    --background: var(--ion-color-light);
    text-align: center;
}

.action-sheet-class .action-sheet-group {
    --color: var(--ion-color-primary);
    --button-color: var(--ion-color-dark);
    max-height: 40%;
}


.footerButton {
    margin: 2%;
    font-size: larger;
    --border-radius: 15px;
}
.footerDoubleButtonLeft {
    margin-left: 2%;
    font-size: larger;
    --border-radius: 15px;
}
.footerDoubleButtonRight {
    margin-right: 2%;
    font-size: larger;
    --border-radius: 15px;
}

.tableHeaderBackground{
    background-color: #eceeef;
}
.searchButton{
    --background:#468BCA;
    --padding-end:0px !important;
    --padding-start:0px
}
.searchbar-input.sc-ion-searchbar-md {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
   
  }
 
  .custom-modal .modal-wrapper {
    height: 300px;

}
.forward-modal .modal-wrapper {
    width: 43%;

}
.custom-modal-charges .modal-wrapper {
   height: 500px;
   width: 400px;

}
.custom-modal-payments .modal-wrapper {
    height: 300px;
    width: 600px;
 
 }
 .custom-modal-kyc .modal-wrapper {
    height: 600px;
    width: 600px;
 
 }
@media only screen and (min-width: 768px) {
  .ion-content{
      --padding-start:5%;
      --padding-end:5%;
  }
 
}
@media only screen and (min-width: 1000px) {
  .ion-content{
      --padding-start:20%;
      --padding-end:20%;
  }
}
@media  (max-width:801px){
    .forward-modal .modal-wrapper{
        width: 100%;
    }
    .custom-modal-charges .modal-wrapper {
        height: 100%;
        width: 100%;
     
     }
     .custom-modal .modal-wrapper {
        height: 100%;
    
        .custom-modal-payments .modal-wrapper {
            height: 100%;
            width: 100%
         
         }
    }
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}
